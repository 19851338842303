import { useEffect, useState } from 'react';
import { VEHICLE_TYPES, vehicleActionsByType } from '../../../domain/vehicle';
import {
  setEngineRunning,
  setGamepadData,
  setManualControlRunning,
} from '../../../store/vehicle/vehicle.slice';
import { socketSend } from '../../../infra/common/socketService';
import {
  disarmCommand,
  manualControlCommand,
  setControlCommand,
  startEngineCommand,
  stopEngineCommand,
} from '../../../infra/common/socketService/commands/commonCommands';
import {
  detectNulls,
  setDataCommandByGamepad,
  setDataCommandBySteeringWheel,
} from '../helpers/gamepadControls';
import { setDataCommandByKeyboard } from '../helpers/keyboardControls';
import { useAppDispatch, useAppSelector } from '../../../store';
import useRadiomaster from './useRadiomaster';

let keyboardInterval: NodeJS.Timeout;
let isArmPressed = false;
let forcedArm = false;
let isSharePressed = false;
let isDisarmPressed = false;
let isBlurActive = false;
let armTimeOut: NodeJS.Timeout;

const useBoatControls = (): void => {
  console.log('boat');
  const dispatch = useAppDispatch();
  const isDeviceOnline = useAppSelector(
    (state) => state.vehicles.activeVehicle?.is_online
  );
  const vehicleType = useAppSelector(
    (state) => state.vehicles.activeVehicle?.vehicle_type
  );

  const gamepadIndex = useAppSelector((state) => state.vehicles?.gamepadIndex);

  const controller = useAppSelector((state) => state.vehicles.controller);

  const gamepadRunning = useAppSelector(
    (state) => state.vehicles.gamepadRunning
  );
  const timeProcessing = useAppSelector((state) => state.app.timeProcessing);
  const manualControlMetric = useAppSelector(
    (state) => state.socket.vehicleMetrics?.is_manual
  );
  const { stopReadingRadiomaster, connectAndReadSerial } = useRadiomaster();
  const isUserOnline = useAppSelector((state) => state.app.isUserOnline);
  const getPingSuccess = useAppSelector((state) => state.app.getPingSuccess);
  const engineRunning = useAppSelector((state) => state.vehicles.engineRunning);
  const [onceNullable, setOnceNullable] = useState<boolean>(false);
  const [commands, setCommands] = useState<any>(null);
  const [
    isStopRadiomasterControllAvailable,
    setIsStopRadiomasterControllAvailable,
  ] = useState(false);

  const isOperatorOnline = getPingSuccess || isUserOnline;

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    if (vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI) {
      return;
    }

    if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.PLANE) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.CAR) {
      return;
    }

    if (!isDeviceOnline || !isOperatorOnline) {
      // dispatch(setManualControlRunning(false));
      clearInterval(keyboardInterval);
    } else {
      keyboardInterval = setInterval(async () => {
        if (
          gamepadRunning &&
          controller === 'gamepad' &&
          vehicleActionsByType[vehicleType as VEHICLE_TYPES].gamepadAvailable &&
          gamepadIndex !== null
        ) {
          const gamepad: Gamepad | null = navigator.getGamepads()[gamepadIndex];
          await dispatch(setGamepadData(gamepad));
          // start arm command, option button
          if (gamepad?.buttons[8].pressed && !isArmPressed && !forcedArm) {
            console.log('work');
            isArmPressed = true;
            await dispatch(setEngineRunning(true));
            if (!engineRunning) {
              await socketSend(
                startEngineCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES].armCommand
                ),
                timeProcessing
              );
              if (
                vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                  ?.stopArmCommandAvailable
              ) {
                armTimeOut = setTimeout(async () => {
                  await socketSend(
                    stopEngineCommand(
                      vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                        ?.stopArmCommand
                    ),
                    timeProcessing
                  );
                  forcedArm = true;
                  isArmPressed = false;
                  await dispatch(setEngineRunning(false));
                }, 7000);
              }
            }
          }

          if (!gamepad?.buttons[8].pressed && isArmPressed && !forcedArm) {
            console.log('work22');

            if (
              vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                ?.stopArmCommandAvailable
            ) {
              clearTimeout(armTimeOut);
              await socketSend(
                stopEngineCommand(
                  vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                    ?.stopArmCommand
                ),
                timeProcessing
              );
            }
            isArmPressed = false;
            await dispatch(setEngineRunning(false));
          }
          if (!gamepad?.buttons[8].pressed) {
            forcedArm = false;
          }
          // end arm command, option button
          if (gamepad?.buttons[2].pressed && !isDisarmPressed) {
            await socketSend(disarmCommand('boat_disarm'), timeProcessing);
            await socketSend(
              manualControlCommand('stop_manual'),
              timeProcessing
            );
            await dispatch(setManualControlRunning(false));
            isDisarmPressed = true;
          }
          if (!gamepad?.buttons[2].pressed && isDisarmPressed) {
            isDisarmPressed = false;
          }

          // start manual control command, share button
          if (
            gamepad?.buttons[9].pressed &&
            !isSharePressed &&
            !manualControlMetric
          ) {
            await socketSend(
              manualControlCommand('start_manual'),
              timeProcessing
            );
            // await dispatch(setManualControlRunning(!manualControlRunning));
            isSharePressed = true;
          }

          if (
            gamepad?.buttons[9].pressed &&
            !isSharePressed &&
            manualControlMetric
          ) {
            await socketSend(
              manualControlCommand('stop_manual'),
              timeProcessing
            );
            // await dispatch(setManualControlRunning(!manualControlRunning));
            isSharePressed = true;
          }
          if (!gamepad?.buttons[9].pressed) {
            isSharePressed = false;
          }
          if (gamepad && manualControlMetric) {
            // end manual control command, share button
            const commands: any = await setDataCommandByGamepad(
              vehicleType as VEHICLE_TYPES,
              gamepad.buttons,
              gamepad.axes
            );

            await setCommands(commands);
            if (detectNulls(commands, vehicleType)) {
              return;
            }

            await setOnceNullable(false);
            await socketSend(
              setControlCommand(
                vehicleActionsByType[vehicleType as VEHICLE_TYPES]
                  .controlCommand,
                commands
              ),
              timeProcessing
            );
          }
        }
      }, 100);
    }
    // eslint-disable-next-line consistent-return
    return () => clearInterval(keyboardInterval);
  }, [
    manualControlMetric,
    gamepadRunning,
    isDeviceOnline,
    isOperatorOnline,
    vehicleType,
    controller,
    gamepadIndex,
  ]);

  useEffect(() => {
    if (!vehicleType) {
      return;
    }

    if (vehicleType === VEHICLE_TYPES.TRACTOR_GEMINI) {
      return;
    }

    if (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.DRONE) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.PLANE) {
      return;
    }
    if (vehicleType === VEHICLE_TYPES.CAR) {
      return;
    }
    const setResetCommand = async () => {
      if (commands && detectNulls(commands, vehicleType) && !onceNullable) {
        await setOnceNullable(true);
        await socketSend(
          setControlCommand(
            vehicleActionsByType[vehicleType as VEHICLE_TYPES].resetCommand,
            commands
          ),
          timeProcessing
        );

        if (gamepadRunning) {
          await dispatch(setGamepadData(navigator.getGamepads()[gamepadIndex]));
        }
      }
    };

    setResetCommand();
  }, [commands, onceNullable, vehicleType]);

  useEffect(() => {
    if (
      manualControlMetric &&
      controller === 'radiomaster' &&
      vehicleActionsByType[vehicleType as VEHICLE_TYPES].radiomasterAvailable
    ) {
      connectAndReadSerial();
      setIsStopRadiomasterControllAvailable(true);
    }
    if (controller === 'gamepad' && isStopRadiomasterControllAvailable) {
      stopReadingRadiomaster();
      setIsStopRadiomasterControllAvailable(false);
    }
  }, [controller, vehicleType, manualControlMetric]);

  const blurStopControl = async () => {
    if (
      manualControlMetric &&
      (vehicleType === VEHICLE_TYPES.TRACTOR_KHTI ||
        vehicleType === VEHICLE_TYPES.CAR ||
        vehicleType === VEHICLE_TYPES.BOAT)
    ) {
      await socketSend(manualControlCommand('stop_manual'), timeProcessing);
      isBlurActive = true;
    }
  };

  useEffect(() => {
    window.addEventListener('blur', blurStopControl);
    return () => {
      window.removeEventListener('blur', blurStopControl);
    };
  }, [manualControlMetric, vehicleType]);
};

export default useBoatControls;
