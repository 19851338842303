import { FC, ReactElement, useEffect, useMemo, memo, useState } from 'react';
import { useMap, Popup, Marker } from 'react-leaflet';
import L, { LatLngBounds, LatLngExpression } from 'leaflet';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { listMapConfig, superCluster } from '../common/config';
import RotatedMarker from './RotatedMarker';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { VEHICLE_TYPES, VehicleMetrics } from '../../../../domain/vehicle';
import { setListMapDragged } from '../../../../store/vehicle/vehicle.slice';
import RotatedSelectedMarker from './RotatedSelectedMarker';
import classes from '../styles/index.module.css';

const {
  bounds: { padding, maxZoom, duration },
} = listMapConfig;

interface ICoordinates {
  bounds: LatLngExpression[];
  dataForClusters: any;
}

interface IProps {
  selectedUuidDevice: string | undefined;
  clustersActive: boolean;
  setClustersActive: (status: boolean) => void;
}

const MarkersContainer: FC<IProps> = memo(
  ({ selectedUuidDevice, setClustersActive, clustersActive }): ReactElement => {
    const dispatch = useAppDispatch();
    const vehiclesListMetrics = useAppSelector(
      (state) => state.vehicles.vehiclesListMetrics
    );

    const [clusters, setClusters] = useState<any[]>([]);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(5);
    const [selectedVehicleCoordinates, setSelectedVehicleCoordinates] =
      useState<VehicleMetrics | null>(null);

    const map = useMap();
    const { t } = useTranslation();

    const coordinates: ICoordinates = useMemo((): ICoordinates => {
      if (vehiclesListMetrics && vehiclesListMetrics.length) {
        return vehiclesListMetrics.reduce(
          (
            acc: ICoordinates,
            { telemetry, vehicle }: VehicleMetrics
          ): ICoordinates => {
            const [vehicleUuid, vehicleType] = vehicle.split(' - ');
            const { position } = telemetry;

            acc.bounds.push(
              L.latLng(
                position ? position.latitude : 0,
                position ? position.longitude : 0
              )
            );
            acc.dataForClusters.push({
              type: 'Feature',
              properties: { vehicleType, vehicleUuid },
              geometry: {
                type: 'Point',
                coordinates: [
                  position ? position.longitude : 0,
                  position ? position.latitude : 0,
                ],
              },
            });

            return acc;
          },
          {
            bounds: [],
            dataForClusters: [],
          }
        );
      }

      return { bounds: [], dataForClusters: [] };
    }, [vehiclesListMetrics]);

    const yawValues: number[] = useMemo((): number[] => {
      if (vehiclesListMetrics && vehiclesListMetrics.length) {
        return vehiclesListMetrics.reduce(
          (acc: number[], { telemetry }: VehicleMetrics): number[] => {
            const yaw = telemetry.attitude?.yaw ?? 0; // Извлечение yaw, если оно существует, или значение по умолчанию 0
            acc.push(yaw);
            return acc;
          },
          []
        );
      }

      return [];
    }, [vehiclesListMetrics]);

    useEffect(() => {
      map.on('zoomend', () => {
        setInitialLoading(true);
        setZoom(map.getZoom());
      });
      map.on('dragend', () => {
        dispatch(setListMapDragged(true));
      });

      return () => {
        dispatch(setListMapDragged(false));
      };
    }, []);

    useEffect(() => {
      if (map) {
        const mapElement: HTMLElement | null = document.getElementById('map');

        if (selectedUuidDevice) {
          map.dragging.disable();
          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          map.scrollWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          if (map.tap) {
            map.tap.disable();
          }

          if (mapElement) {
            mapElement.style.cursor = 'default';
          }
        } else {
          map.dragging.enable();
          map.touchZoom.enable();
          map.doubleClickZoom.enable();
          map.scrollWheelZoom.enable();
          map.boxZoom.enable();
          map.keyboard.enable();
          if (map.tap) {
            map.tap.enable();
          }

          if (mapElement) {
            mapElement.style.cursor = 'grab';
          }
        }
      }
    }, [map, selectedUuidDevice]);

    useEffect(() => {
      if (vehiclesListMetrics) {
        if (selectedUuidDevice) {
          const selectedVehicle: VehicleMetrics | undefined =
            vehiclesListMetrics.find(({ vehicle }: VehicleMetrics): boolean => {
              const [vehicleUuid] = vehicle.split(' - ');
              return vehicleUuid === selectedUuidDevice;
            });

          if (selectedVehicle) {
            setSelectedVehicleCoordinates(selectedVehicle);

            if (clustersActive) {
              setClustersActive(false);
            }
          }
        } else {
          setSelectedVehicleCoordinates(null);
          if (!clustersActive) {
            setClustersActive(true);
          }
        }
      }
    }, [selectedUuidDevice, vehiclesListMetrics, clustersActive]);

    useEffect(() => {
      if (clustersActive) {
        return;
      }

      if (coordinates.bounds.length) {
        if (!selectedUuidDevice) {
          map.flyToBounds(L.latLngBounds(coordinates.bounds) as LatLngBounds, {
            padding,
            maxZoom,
            duration,
          });
          setClustersActive(false);
        }
      }
    }, [clustersActive, coordinates.bounds, selectedUuidDevice]);

    // const bounds = map.getBounds();
    // if (bounds && !bounds.contains(coordinates.bounds as any)) {
    //   map.flyToBounds(
    //     L.latLngBounds(coordinates.bounds) as LatLngBounds,
    //     {
    //       padding,
    //       maxZoom: map.getZoom(),
    //       duration,
    //     }
    //   );
    // }
    //   }
    // }
    // }, [coordinates.bounds]);

    useEffect(() => {
      if (coordinates.dataForClusters.length) {
        superCluster.load(coordinates.dataForClusters as any);
        const clusterBounds: LatLngBounds = map.getBounds();
        const clusters: any = superCluster.getClusters(
          [
            clusterBounds.getWest(),
            clusterBounds.getSouth(),
            clusterBounds.getEast(),
            clusterBounds.getNorth(),
          ],
          Math.floor(zoom)
        );
        setClusters(clusters);
      } else {
        setClusters([]);
      }
    }, [coordinates.dataForClusters, initialLoading, selectedUuidDevice, zoom]);

    const locationPinClickHandler = ([latitude, longitude]: [
      number,
      number,
    ]): void => {
      const bounds: LatLngExpression[] = [L.latLng(latitude, longitude)];
      map.flyToBounds(L.latLngBounds(bounds) as LatLngBounds, {
        padding: [20, 20],
        maxZoom: 12,
        duration,
      });
      setClustersActive(true);
    };

    const displayVehicleLabel = (vehicleType: VEHICLE_TYPES): string => {
      switch (vehicleType) {
        case VEHICLE_TYPES.TRACTOR:
          return t('vehiclesList.listDeviceTractorLabel');
        case VEHICLE_TYPES.CAR:
          return t('vehiclesList.listDeviceCarLabel');
        case VEHICLE_TYPES.BOAT:
          return t('vehiclesList.listDeviceBoatLabel');
        case VEHICLE_TYPES.DRONE:
          return t('vehiclesList.listDeviceDroneLabel');
        case VEHICLE_TYPES.PLANE:
          return t('vehiclesList.listDevicePlaneLabel');
        case VEHICLE_TYPES.TRACTOR_GEMINI:
          return t('vehiclesList.listDeviceTractorGeminiLabel');
        case VEHICLE_TYPES.TRACTOR_KHTI:
          return t('vehiclesList.listDeviceTractorHTILabel');
        default:
          return '';
      }
    };

    if (selectedVehicleCoordinates) {
      return (
        <RotatedSelectedMarker
          vehicleID={selectedVehicleCoordinates.vehicle.split(' - ')[0]}
          vehicleType={selectedVehicleCoordinates.vehicle.split(' - ')[1]}
          latitude={
            selectedVehicleCoordinates.telemetry.position
              ? selectedVehicleCoordinates.telemetry.position.latitude
              : 0
          }
          longitude={
            selectedVehicleCoordinates.telemetry.position
              ? selectedVehicleCoordinates.telemetry.position.longitude
              : 0
          }
        />
      );
    }

    return (
      <Box>
        {clusters.map(
          (
            {
              id,
              geometry: {
                coordinates: [longitude, latitude],
              },
              properties,
            }: any,
            index: number
          ): ReactElement =>
            id ? (
              <Marker
                key={properties.cluster_id}
                position={[latitude, longitude]}
                icon={L.divIcon({
                  className: classes.clusterMarker,
                  html: `<section>${properties.point_count_abbreviated}</section>`,
                })}
                eventHandlers={{
                  click: (): void =>
                    locationPinClickHandler([latitude, longitude]),
                }}
              />
            ) : (
              <RotatedMarker
                key={index}
                position={[latitude, longitude]}
                yawValues={yawValues[index]}
                locationPinClickHandler={locationPinClickHandler}
                vehicleType={properties.vehicleType}
                vehicleUuid={properties.vehicleUuid}
              >
                <Popup>
                  <div>
                    {t('vehiclesList.latitude')}: {latitude}
                  </div>
                  <div>
                    {t('vehiclesList.longitude')}: {longitude}
                  </div>
                  <div>
                    {t('vehiclesList.vehicleTypeLabel')}:{' '}
                    {displayVehicleLabel(properties.vehicleType)}
                  </div>
                </Popup>
              </RotatedMarker>
            )
        )}
      </Box>
    );
  }
);

export default MarkersContainer;
