import {
  FC,
  forwardRef,
  ReactElement,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import L, { LatLngExpression } from 'leaflet';
import { Marker, useMap } from 'react-leaflet';
import { fromRadiansToDegrees, slidesMiniMapConfig } from '../common/config';
import 'leaflet-rotatedmarker';

export const {
  marker: { rotationOrigin },
} = slidesMiniMapConfig;

interface IProps {
  position: LatLngExpression;
  yaw: number | undefined;
  reducePin: boolean;
  centered: boolean;
}

const RotatedMarker: FC<IProps> = forwardRef(
  (
    { position, yaw, reducePin, centered },
    // eslint-disable-next-line no-shadow
    forwardRef: any
  ): ReactElement => {
    const map = useMap();
    const markerRef: any = useRef();
    const rotationAngle: number = useMemo(
      // @ts-ignore
      (): number => fromRadiansToDegrees(yaw),
      [yaw]
    );

    useEffect(() => {
      if (centered) {
        map.setView(position, map.getZoom());
      }
    }, [position, centered]);

    useEffect(() => {
      const marker = markerRef.current;

      if (marker) {
        marker.setRotationAngle(rotationAngle);
        marker.setRotationOrigin(rotationOrigin);
      }
    }, [rotationAngle, rotationOrigin]);

    return (
      <Marker
        ref={(ref) => {
          markerRef.current = ref;
          if (forwardRef) {
            // eslint-disable-next-line no-param-reassign
            forwardRef.current = ref;
          }
        }}
        icon={L.divIcon({
          html: reducePin
            ? `
            <svg width="20" height="25" viewBox="0 0 30 35" xmlns="http://www.w3.org/2000/svg" style='transform: rotate(${yaw}deg)'>
              <path d="M16.8598 1.71961L29.1598 31.6996C29.8398 33.3596 28.1398 35.0196 26.4998 34.2796L15.8198 29.5596C15.2998 29.3396 14.7198 29.3396 14.1998 29.5596L3.5198 34.2796C1.8798 34.9996 0.179803 33.3596 0.859803 31.6996L13.1598 1.71961C13.8198 0.0596094 16.1798 0.0596094 16.8598 1.71961Z" fill="rgba(249, 168, 37, 1)"/>
            </svg>
          `
            : `
            <svg width="30" height="35" viewBox="0 0 30 35" xmlns="http://www.w3.org/2000/svg" style='transform: rotate(${yaw}deg)'>
              <path d="M16.8598 1.71961L29.1598 31.6996C29.8398 33.3596 28.1398 35.0196 26.4998 34.2796L15.8198 29.5596C15.2998 29.3396 14.7198 29.3396 14.1998 29.5596L3.5198 34.2796C1.8798 34.9996 0.179803 33.3596 0.859803 31.6996L13.1598 1.71961C13.8198 0.0596094 16.1798 0.0596094 16.8598 1.71961Z" fill="rgba(249, 168, 37, 1)"/>
            </svg>
          `,
        })}
        position={position}
      />
    );
  }
);

export default RotatedMarker;
